import { gql, useQuery } from '@apollo/client'
import { DocumentNode } from 'graphql/language'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { Event, EventConnection, Maybe } from '@/graphql/generated'

type Props = {
  alias: string
  from?: Date
  to?: Date
  isReady?: boolean
}
type ReturnType = {
  loading: boolean
  loadingMore: boolean
  events: Maybe<Event | undefined>[]
  loadMore: () => void
}

type LCEventsResult = {
  learningCommunity: {
    [key in string]: EventConnection
  }
}

export const PAGE_SIZE = 12

const useLearningCommunityEvents = ({ alias, from, to, isReady }: Props = { alias: 'events' }): ReturnType => {
  const [loadingMore, setLoadingMore] = useState(false)
  const {
    isReady: isRouterReady,
    query: { slug },
  } = useRouter()

  const { data, loading, fetchMore } = useQuery<LCEventsResult>(GET_EVENTS(alias), {
    variables: { slug, first: PAGE_SIZE, from, to },
    skip: !isReady || !isRouterReady,
  })

  const loadMore = async (): Promise<void> => {
    if (!data?.learningCommunity?.[alias]?.pageInfo?.hasNextPage) return

    setLoadingMore(true)
    await fetchMore?.({
      variables: {
        first: PAGE_SIZE,
        after: data?.learningCommunity?.[alias]?.pageInfo?.endCursor,
        to,
        from,
      },
    })
    setLoadingMore(false)
  }

  return {
    events: data?.learningCommunity?.[alias]?.edges?.map(edge => edge?.node) ?? [],
    loading,
    loadMore,
    loadingMore,
  }
}

export const GET_EVENTS = (alias: string): DocumentNode => gql`
  query GetEvents($slug: String!, $first: Int, $from: ISO8601DateTime, $to: ISO8601DateTime, $after: String) {
    learningCommunity(slug: $slug) {
      id
      ${alias}: events(first: $first, from: $from, to: $to, after: $after) {
        pageInfo {
          endCursor
          startCursor
          hasPreviousPage
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            title
            description
            imageUrl
            location
            startsAt
            durationInMinutes
            creator {
              id
            }
            videoMeeting {
              id
              url
              shareableUrl
            }
            peopleAttending {
              id
              avatarUrl
              fullName
            }
          }
        }
      }
    }
  }
`

export default useLearningCommunityEvents
