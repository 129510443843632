import classNames from 'classnames'
import { format } from 'date-fns'

type Props = {
  date: Date
  size?: 'sm' | 'md' | 'lg'
}

const CalendarDay: React.FC<React.PropsWithChildren<Props>> = ({ date, size = 'lg' }) => {
  const classes = {
    container: classNames('flex flex-col border-2 border-red-base bg-red-base', {
      'w-8 h-10': size === 'sm',
      'w-12 h-14 rounded-sm': size === 'md',
      'w-14 h-14 rounded-md': size === 'lg',
    }),
    date: classNames('grow bg-white text-center font-medium', {
      'text-sm rounded-b-sm': size === 'sm',
      'text-md rounded-b-sm': size === 'md',
      'text-[20px] rounded-b-md': size === 'lg',
    }),
    month: classNames('rounded-t-md py-0.5 text-center uppercase text-white', {
      'text-xs': size === 'sm',
      'text-sm w-12 h-6 pt-0': size === 'md',
      'text-[20px] text-sm-none': size === 'lg',
    }),
  }

  return (
    <div className={classes.container}>
      <div className={classes.month}>{format(date, 'MMM')}</div>
      <div className={classes.date}>{date.getDate()}</div>
    </div>
  )
}

export default CalendarDay
