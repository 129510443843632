import { parseISO } from 'date-fns'

export const formatDate = (
  date: string | null,
  options?: Intl.DateTimeFormatOptions | undefined,
  locales = 'en-GB',
): string => {
  // Using `en-GB` because I know it formats with
  // the same format provided in the mockups.
  return new Intl.DateTimeFormat(locales, options).format(date ? parseISO(date) : new Date())
}
