import { gql, useQuery } from '@apollo/client'
import { t } from '@lingui/macro'
import { isAfter, parseISO } from 'date-fns'
import Link from 'next/link'
import { useEffect, useState } from 'react'

import { AvatarGroup, Card, Heading, Progress, Spinner, Text } from '@/components'
import CalendarDay from '@/components/CalendarDay/CalendarDay'
import { IconCheckCircle } from '@/components/Icons'
import PrivateRoute from '@/containers/PrivateRoute/PrivateRoute'
import { useCurrentUserContext } from '@/contexts/CurrentUserContext'
import { useLearningCommunityContext } from '@/contexts/LearningCommunityContext'
import { Cohort, Event, Query } from '@/graphql/generated'
import useLearningCommunityEvents from '@/hooks/useLearningCommunityEvents'
import useRoutes from '@/hooks/useRoutes'
import { formatDate } from '@/utils/date'

import { NextPageWithLayout } from '../_app'

const CohortCard: React.FC<Cohort> = ({ id, name, schedule, learningPath, fellows }) => {
  const { getCohortHomeRoute } = useRoutes()
  const learningCommunityContext = useLearningCommunityContext()
  const calculateProgress = (): number => {
    let totalBlocks = 0
    let completedBlocks = 0

    learningPath?.sections?.forEach(section => {
      section.blocks.forEach(block => {
        totalBlocks++

        if (block.viewerStatus.markedAsDoneAt !== null) {
          completedBlocks++
        }
      })
    })

    const percentage = Math.round((completedBlocks / totalBlocks) * 100)

    return percentage || 0
  }

  const cohortCompletedPercentage = calculateProgress()

  return (
    <Card padding="md">
      <Heading level={4} variant={TextStyle => TextStyle.MEDIUM_BASE_NORMAL}>
        <Link href={getCohortHomeRoute(learningCommunityContext?.learningCommunity?.slug ?? '', id)} passHref>
          {name}
        </Link>
      </Heading>
      <Text variant={TextStyle => TextStyle.REGULAR_SM_SNUG} className="mt-2 text-grey-dark">
        {isAfter(parseISO(schedule?.startDate ?? ''), new Date()) ? t`Starting` : t`Started`}{' '}
        {formatDate(schedule?.startDate, {
          month: 'long',
          day: 'numeric',
        })}
      </Text>
      <div className="mt-2 flex flex-col space-y-3">
        <div className="space-y-2">
          <Text>
            {t`Progress`} · <strong>{cohortCompletedPercentage}%</strong>
          </Text>
          <Progress percent={cohortCompletedPercentage} size="small" color="green" />
        </div>

        {fellows?.edges?.length ? (
          <div className="flex flex-row items-center space-x-3">
            <AvatarGroup
              images={fellows?.edges?.map(fellow => ({
                name: fellow?.node?.fullName ?? '',
                url: fellow?.node?.avatarUrl ?? '',
              }))}
              size="md"
              shouldHideImageCount
            />
            <Text variant={TextStyle => TextStyle.REGULAR_SM_SNUG} className="text-grey-dark">
              {t`${fellows.totalCount} learners`}
            </Text>
          </div>
        ) : null}
      </div>
    </Card>
  )
}

const EventCard: React.FC<Event> = ({ id, title, description, startsAt, peopleAttending }) => {
  const { getEventRoute } = useRoutes()
  const currentUserContext = useCurrentUserContext()
  const isAttending = peopleAttending?.find(user => user.id === currentUserContext?.currentUser?.me?.id)

  return (
    <Card padding="sm">
      <Link href={getEventRoute(id ?? '')} className="flex flex-row items-center">
        <CalendarDay date={new Date(startsAt)} size="md" />
        <div className="flex w-[100%] flex-row items-center justify-between truncate">
          <div className="-mt-1 ml-3 flex flex-col justify-center truncate">
            <Text className="truncate capitalize" variant={TextStyle => TextStyle.MEDIUM_SM_SNUG}>
              {title}
            </Text>
            <Text className="text-grey-dark" variant={TextStyle => TextStyle.REGULAR_SM_SNUG}>
              {description}
            </Text>
          </div>
          {isAttending ? <IconCheckCircle className="icon-md ml-2 text-grey-dark" /> : null}
        </div>
      </Link>
    </Card>
  )
}

const LearningCommunity: NextPageWithLayout = () => {
  const learningCommunityContext = useLearningCommunityContext()
  const currentUserContext = useCurrentUserContext()
  const [currentDate, setCurrentDate] = useState<Date>()

  useEffect(() => {
    setCurrentDate(new Date())
  }, [])

  const { events } = useLearningCommunityEvents({
    from: currentDate,
    alias: 'upcoming',
    isReady: !!currentDate,
  })

  const { data } = useQuery<Pick<Query, 'learningCommunity'>>(GET_COHORTS_IN_LEARNING_COMMUNITY, {
    variables: { learningCommunitySlug: learningCommunityContext?.learningCommunity?.slug },
    skip: !learningCommunityContext?.learningCommunity,
  })

  return !data ? (
    <div className="flex h-screen w-full items-center justify-center">
      <Spinner size="lg" />
    </div>
  ) : (
    <div className="my-8 mx-auto w-full space-y-8 px-8">
      <Heading level={2} variant={TextStyle => TextStyle.MEDIUM_XL_NORMAL}>
        {t`Hi ${currentUserContext?.currentUser?.me?.firstName}!`}
      </Heading>
      <div className="flex space-x-8">
        <div className="w-full space-y-4">
          <Heading level={3} variant={TextStyle => TextStyle.MEDIUM_LG_NORMAL}>{t`Your experiences`}</Heading>
          <div className="grid grid-cols-1 gap-4 2xl:grid-cols-2">
            {data.learningCommunity?.cohorts.map(cohort => (
              <CohortCard key={cohort.id} {...cohort} />
            ))}
          </div>
        </div>
        <div className="w-full max-w-[420px] space-y-4">
          <Heading level={3} variant={TextStyle => TextStyle.MEDIUM_LG_NORMAL}>{t`Upcoming events`}</Heading>
          {events?.map(event => (event ? <EventCard key={event.id} {...event} /> : null))}
        </div>
      </div>
    </div>
  )
}

LearningCommunity.getLayout = function getLayout(page: JSX.Element) {
  return <PrivateRoute>{page}</PrivateRoute>
}

export const GET_COHORTS_IN_LEARNING_COMMUNITY = gql`
  query GetCohortsInLearningCommunity($learningCommunitySlug: String!) {
    learningCommunity(slug: $learningCommunitySlug) {
      id
      cohorts {
        id
        name
        schedule {
          startDate
        }
        fellows(first: 3) {
          edges {
            cursor
            node {
              fullName
              avatarUrl
            }
          }
          totalCount
        }
        learningPath {
          sections {
            id
            schedule {
              endDate
              startDate
            }
            viewerStatus {
              isComplete
              progress
            }
            blocks {
              id
              viewerStatus {
                markedAsDoneAt
              }
            }
          }
        }
      }
    }
  }
`

export default LearningCommunity
