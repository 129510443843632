import classNames from 'classnames'

type Props = {
  label?: string
  percent: number
  size: 'small' | 'medium' | 'large'
  color: 'purple' | 'purple-gradient' | 'green'
  animated?: boolean
}

const Progress: React.FC<React.PropsWithChildren<Props>> = ({ label, percent, size, color, animated = false }) => {
  const sizeClass = size === 'large' ? 'h-7' : size === 'medium' ? 'h-4' : 'h-1.5'

  const classes = {
    container: classNames(
      'relative flex items-center bg-grey-light rounded-full overflow-hidden min-w-[100%]',
      sizeClass,
    ),
    progress: classNames(
      'rounded',
      sizeClass,
      color === 'purple'
        ? 'bg-purple-base'
        : color === 'green'
        ? 'bg-green-bright'
        : 'bg-gradient-to-r from-purple-light to-purple-dark',
      animated ? 'w-0 animate-fill' : '',
    ),
    label: classNames('w-full mt-1 text-center text-sm absolute font-semibold top-0 text-white'),
  }

  return (
    <div className={classes.container}>
      <div className={classes.progress} style={{ width: `${percent}%` }} />
      {label ? <div className={classes.label}>{label}</div> : null}
    </div>
  )
}

export default Progress
